:root {
  /** FONTS **/
  --font-family: 'Scope One', serif;
  /** COLORS **/
  --charcoal: #202020;
  --dark-blue: #0b1215;
  --dark-brown: #b3814e;
  --gold: #e4a125;
  --sand: #eccba7;
  --light-brown: #beab9e;
  --dark-turq: #1f4752;
  --medium-turq: #4b7d8d;
  --light-turq: #bbd5db;
  --gray: #CFCFD1;
  --white: #f2f2f6;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--white);
  background-color: var(--charcoal);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
